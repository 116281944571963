<script>
import {mapActions, mapGetters} from "vuex";
import {Modal as _BSModal} from "bootstrap";
import {GameType} from "../../../gameCommon";
// import {convertToDateString} from "@/utils/tools";

export default {
  name: "HandHistory",
  components: {
    HandHistoryItems: () => import(/* webpackChunkName: "hand-history-items-v2" */ '@/components/HandHistoryItems.vue'),
  },
  props: {
    value: Boolean
  },
  data() {
    return {
      modal: false,
      displayListType : 'relay',
      relayList: [],
      tournamentList: [],
    }
  },
  computed: {
    ...mapGetters(['token', 'sessionData']),
    ...mapGetters('user', ['targetHistoryDate', 'gamePlayDates', 'gameHistory']),
    gameDates() {
      if (this.gamePlayDates.length === 0) {
        return [this.targetDate];
      }

      return this.gamePlayDates;
    },
    targetDate: {
      get() {
        return this.targetHistoryDate;
      },
      set(date) {
        this.$store.commit('user/SET_TARGET_HISTORY_DATE', date);
      }
    },
    relayRoomCodes(){
      return [...new Set(this.relayList.map(data => data.game_sn))];
    },
    tournamentRoomCodes(){
      return [...new Set(this.tournamentList.map(data => data.game_sn))];
    },
    roomCodes() {
      return [...new Set(this.gameHistory.map(data => data.game_sn))];
    }
  },
  watch: {
    value() {
      this.value ? this.show() : this.hide();
    },
    token: {
      immediate: true,
      handler() {
        this.fetchGamePlayDates(this.token);
      }
    },
    targetDate: {
      immediate: true,
      handler() {
        this.fetchGameHistory({
          token: this.token,
          date: this.targetDate,
        });
      }
    },
    gameHistory: {
      immediate: true,
      handler: 'updateList'
    },
  },
  methods: {
    ...mapActions('user', ['fetchGamePlayDates', 'fetchGameHistory']),
    hide() {
      this.modal.hide();
    },
    show() {
      this.modal.show();
    },
    shown() {
      this.$emit('input', true);
    },
    hidden() {
      this.$emit('input', false);
    },
    showDetails(data) {
      this.$emit('modal', data)
    },
    isDisplay( type ){
      return this.displayListType === type;
    },
    changeDisplay( type ){
      this.displayListType = type;
    },
    updateList(){
      this.relayList = this.gameHistory.filter(handHistory => handHistory.game_type ===  GameType.Relay);
      this.tournamentList = this.gameHistory.filter(handHistory => handHistory.game_type ===  GameType.Tournament);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.root.addEventListener('shown.bs.modal', this.shown, false);
      this.$refs.root.addEventListener('hidden.bs.modal', this.hidden, false);

      this.modal = _BSModal.getOrCreateInstance(this.$refs.root);
    });
  },
}
</script>

<template>
  <!-- Modal -->
  <div ref="root" class="modal basic_modal fade wrap_hand_history" id="hand_history" aria-labelledby="hand_history" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-lg-down">
      <div class="modal-content wrap_member">
        <div class="modal-header">
          <button type="button" class="btn_back" data-bs-dismiss="modal" aria-label="Close"></button>
          <h1 class="modal-title">핸드히스토리</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="wrap_tab_history">
            <ul class="tab_history">
              <li :class="{active:isDisplay('relay')}" @click.stop.prevent="changeDisplay('relay')" ><a href="javascript:void(0);">홀덤</a></li>
              <li :class="{active:isDisplay('tournament')}" @click.stop.prevent="changeDisplay('tournament')"><a href="javascript:void(0);">토너먼트</a></li>
<!--              <li><a href="javascript:void(0);">AOF</a></li>-->
            </ul>
          </div>
          <div class="list_history" v-show="isDisplay('relay')">
            <div class="area_sort">
              <select class="form-control form-select" v-model="targetDate">
                <option v-for="date in gameDates" :key="date">{{ date }}</option>
              </select>
            </div>

            <!-- 핸드 히스토리 -->
            <template v-if="relayRoomCodes.length" v-show="isDisplay('relay')">
              <HandHistoryItems
                v-for="roomId in relayRoomCodes"
                :key="roomId"
                :room-id="roomId"
                @modal="showDetails"
              />
            </template>
            <template v-else>
              <div class="text-center my-5" :style="{ fontSize: `1.5rem` }">게임 기록이 없습니다.</div>
            </template>
          </div>
          <div class="list_history" v-show="isDisplay('tournament')">
            <div class="area_sort">
              <select class="form-control form-select" v-model="targetDate">
                <option v-for="date in gameDates" :key="date">{{ date }}</option>
              </select>
            </div>

            <!-- 핸드 히스토리 -->
            <template v-if="tournamentRoomCodes.length">
              <HandHistoryItems
                  v-for="roomId in tournamentRoomCodes"
                  :key="roomId"
                  :room-id="roomId"
                  @modal="showDetails"
              />
            </template>

            <template v-else>
              <div class="text-center my-5" :style="{ fontSize: `1.5rem` }">게임 기록이 없습니다.</div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>